import type {Board} from '@/model/board/types';
import type {BoardItem, BoardItemWithOrder} from '@/model/board_item/types';
import type {BoardItemOrder} from '@/model/board_item_order/types';
import type {ExtendedOrg, Org} from '@/model/org/types';
import type {Product} from '@/model/product/types';
import type {Vendor} from '@/model/vendor/types';
import type {Brand} from '@/model/brand/types';
import type {ScrapeProductAttempt} from '@/model/scrape_product_attempt/types';
import type {ScrapeProductAttemptStatus} from '@/model/scrape_product_attempt_status/types';
import type {Offer} from '@/model/offer/types';
import type {ProductStyle} from '@/model/product_style/types';
import type {ProductMedia} from '@/model/product_media/types';
import type {OrgProduct} from '@/model/org_product/types';
import type {Deal} from '@/model/deal/types';
import type {DealUser} from '@/model/deal_user/types';
// CODE-GENERATOR-SLUG imports

const SocketUpdateEventTypes = {
  Org: {
    Created: 'org-created',
    Updated: 'org-updated',
    Deleted: 'org-deleted',
  },
  Board: {
    Created: 'board-created',
    Updated: 'board-updated',
    Published: 'board-published',
    Unpublished: 'board-unpublished',
    Deleted: 'board-deleted',
  },
  BoardItem: {
    Created: 'board-item-created',
    Updated: 'board-item-updated',
    Deleted: 'board-item-deleted',
  },
  BoardItemOrder: {
    Updated: 'board-item-order-updated',
  },
  Product: {
    Created: 'product-created',
    Updated: 'product-updated',
    Deleted: 'product-deleted',
  },
  Vendor: {
    Created: 'vendor-created',
    Updated: 'vendor-updated',
    Deleted: 'vendor-deleted',
  },
  Brand: {
    Created: 'brand-created',
    Updated: 'brand-updated',
    Deleted: 'brand-deleted',
  },
  ScrapeProductAttempt: {
    Created: 'scrape-product-attempt-created',
    Updated: 'scrape-product-attempt-updated',
    Deleted: 'scrape-product-attempt-deleted',
  },
  Offer: {
    Created: 'offer-created',
    Updated: 'offer-updated',
    Deleted: 'offer-deleted',
  },
  ProductStyle: {
    Created: 'product-style-created',
    Updated: 'product-style-updated',
    Deleted: 'product-style-deleted',
  },
  ProductMedia: {
    Created: 'product-media-created',
    Updated: 'product-media-updated',
    Deleted: 'product-media-deleted',
  },
  OrgProduct: {
    Created: 'org-product-created',
    Updated: 'org-product-updated',
    Deleted: 'org-product-deleted',
  },
  Deal: {
    Created: 'deal-created',
    Updated: 'deal-updated',
    Deleted: 'deal-deleted',
  },
  // CODE-GENERATOR-SLUG event types
} as const;

export default SocketUpdateEventTypes;

export type SocketUpdateEvent =
  | {
      type: typeof SocketUpdateEventTypes.Org.Created;
      data: ExtendedOrg;
    }
  | {
      type: typeof SocketUpdateEventTypes.Org.Updated;
      data: Org;
    }
  | {
      type: typeof SocketUpdateEventTypes.Org.Deleted;
      data: Org;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Created;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Updated;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Published;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Unpublished;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Deleted;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Created;
      data: BoardItemWithOrder;
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Updated;
      data: BoardItem;
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Deleted;
      data: BoardItem;
    }
  | {
      type: typeof SocketUpdateEventTypes.Product.Created;
      data: Product;
    }
  | {
      type: typeof SocketUpdateEventTypes.Product.Updated;
      data: Product;
    }
  | {
      type: typeof SocketUpdateEventTypes.Product.Deleted;
      data: Product;
    }
  | {
      type: typeof SocketUpdateEventTypes.Vendor.Created;
      data: Vendor;
    }
  | {
      type: typeof SocketUpdateEventTypes.Vendor.Updated;
      data: Vendor;
    }
  | {
      type: typeof SocketUpdateEventTypes.Vendor.Deleted;
      data: Vendor;
    }
  | {
      type: typeof SocketUpdateEventTypes.Brand.Created;
      data: Brand;
    }
  | {
      type: typeof SocketUpdateEventTypes.Brand.Updated;
      data: Brand;
    }
  | {
      type: typeof SocketUpdateEventTypes.Brand.Deleted;
      data: Brand;
    }
  | {
      type: typeof SocketUpdateEventTypes.ScrapeProductAttempt.Created;
      data: ScrapeProductAttempt;
    }
  | {
      type: typeof SocketUpdateEventTypes.ScrapeProductAttempt.Updated;
      data: ScrapeProductAttempt;
    }
  | {
      type: typeof SocketUpdateEventTypes.ScrapeProductAttempt.Deleted;
      data: ScrapeProductAttempt;
    }
  | {
      type: typeof SocketUpdateEventTypes.Offer.Created;
      data: Offer;
    }
  | {
      type: typeof SocketUpdateEventTypes.Offer.Updated;
      data: Offer;
    }
  | {
      type: typeof SocketUpdateEventTypes.Offer.Deleted;
      data: Offer;
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductStyle.Created;
      data: ProductStyle;
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductStyle.Updated;
      data: ProductStyle;
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductStyle.Deleted;
      data: ProductStyle;
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductMedia.Created;
      data: ProductMedia;
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductMedia.Updated;
      data: ProductMedia;
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductMedia.Deleted;
      data: ProductMedia;
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgProduct.Created;
      data: OrgProduct;
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgProduct.Updated;
      data: OrgProduct;
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgProduct.Deleted;
      data: OrgProduct;
    }
  | {
      type: typeof SocketUpdateEventTypes.Deal.Created;
      data: Deal;
    }
  | {
      type: typeof SocketUpdateEventTypes.Deal.Updated;
      data: Deal;
    }
  | {
      type: typeof SocketUpdateEventTypes.Deal.Deleted;
      data: Deal;
    }
  // CODE-GENERATOR-SLUG actual types
  | {
      type: typeof SocketUpdateEventTypes.BoardItemOrder.Updated;
      data: Pick<BoardItemOrder, 'boardItemId' | 'boardItemType'> & {
        before: Pick<BoardItemOrder, 'order' | 'parentBoardItemId'>;
        after: Pick<BoardItemOrder, 'order' | 'parentBoardItemId'>;
      };
    };
